(function () {
    'use strict';
    var _ = window._;
    var $ = window.$;

    angular.module('lucidity')
        .constant('_', _)
        .constant('$', $)
        .constant('CKEDITOR', window.CKEDITOR)
        .constant('uuid', window.uuid)
        .constant('moment', window.moment)
        .constant('Arboreal', window.Arboreal)
        .constant('Modernizr', window.Modernizr)
        .constant('Backbone', window.Backbone)
        .constant('ckeditorDialog', null)
        .constant('lucidityConfig', window.lucidityConfig || {})
        .constant('applicationName', applicationName)
        .constant('userPermissions', window.userPermissions || {})
        .constant('enabledFeatures', window.enabledFeatures || {})
        .constant('user', window.user || {})
        .constant('ckeditorConfig', {
            customConfig: '/static/js/ckeditor_config.js',
        })
        .constant('ckeditorInlineConfig', {
            customConfig: '/static/js/ckeditorinline_config.js',
        })
        .constant('ckeditorNotificationConfig', {
            customConfig: '/static/js/ckeditor_notification_config.js',
        })
        .constant('ckeditorIntranetConfig', {
            customConfig: '/static/js/ckeditor_intranet_config.js',
        })
        .constant('CACHEID', cacheKey())
        .constant('XSRFTOKEN', (function () {
            var xsrfToken = (document.cookie.match(/XSRF-TOKEN=([^ ;]+)/) || ['', ''])[1];
            return decodeURIComponent(xsrfToken);
        })());

    function applicationName() {
        if (window.cruseconf !== undefined) {
            return window.cruseconf.application.name;
        }

        return 'lucidityintranet';
    }

    function cacheKey() {
        return 'pleasefixme';
    }

    function nested(obj, key, defaultValue) {

        if (!obj || !key) {
            return undefined;
        }

        key = _.isArray(key) ? key.join('.') : key;
        var value = obj[key] || _.reduce(key.split('.'), function (obj, key) {
                return obj ? obj[key] : undefined;
            }, obj);

        return (value !== undefined) ? value : defaultValue;
    }

    function setNested(collection, key, value) {

        var splitKey = key.split(/\./);
        var currentObject = collection;
        for (var index = 0; index < splitKey.length; index++) {
            //Set value
            var property = splitKey[index];
            if (index + 1 === splitKey.length) {
                currentObject[property] = value;
            }

            if (currentObject[property] === undefined) {
                currentObject[property] = {};
            }

            currentObject = currentObject[property];
        }

        return collection;
    }

    function ngSafe(string) {
        return (string + '').replace(/-/g, '_');
    }

    function itemMatches(key, matchValue) {
        var isArray = _.isArray(matchValue);
        return function (item) {
            if (item[key] === undefined) {
                return true;
            } else if (!isArray) {
                return item[key] === matchValue;
            } else {
                return matchValue.indexOf(item[key]) !== -1;
            }
        };
    }

    function updateMatchingItem(key, matchValue, newItem) {
        return function (item) {
            if (item[key] !== undefined && item[key] === matchValue) {
                _.merge(item, newItem);
            }
            return true;
        };
    }

    function removeByKey(collection, key, matchValue) {
        return _.remove(collection, itemMatches(key, matchValue));
    }

    function updateByKey(collection, key, matchValue, newItem) {
        return _.filter(collection, updateMatchingItem(key, matchValue, newItem));
    }

    function filterByKey(collection, key, matchValue) {
        return _.filter(collection, itemMatches(key, matchValue));
    }

    function moveBy(collection, element, move) {
        var updatedCollection = collection;
        var length = collection.length;
        var currentIndex = collection.indexOf(element);
        var finalIndex = currentIndex + move;
        finalIndex = (finalIndex < 0) ? 0 : finalIndex;
        finalIndex = (finalIndex > length) ? length : finalIndex;
        updatedCollection.splice(finalIndex, 0, updatedCollection.splice(currentIndex, 1)[0]);
        return updatedCollection;
    }

    _.mixin({
        nested: nested,
        getNested: nested,
        setNested: setNested,
        ngSafe: ngSafe,
        filterByKey: filterByKey,
        removeByKey: removeByKey,
        updateByKey: updateByKey,
        moveBy: moveBy,
        deepClone: _.cloneDeep,
    });

    //callback function for google map API
    if (window.isGoogleMapLoaded === undefined) {
        window.isGoogleMapLoaded = false;
    }

    if (window.googleMapLoaded === undefined) {
        window.googleMapLoaded = function(){
            window.isGoogleMapLoaded = true;
        }
    }

})();

