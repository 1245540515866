(function () {
    'use strict';
    var app = angular.module('lucidity', [
        'restangular',
        'flow',
        'ui.bootstrap',
        'ui.select',
        'ui.sortable',
        'ngRoute',
        'ngSanitize',
        'ngStorage',
        'angular-ladda',
        'checklist-model',
        'dndLists',
        'ui.tree',
        'angularSpectrumColorpicker',
        'ngMap',
    ]);

    // dummy filters as a workaround for same name twig function causing angularjs think its an expression
    app.filter('raw', function () {
        return function (value) {
            return value;
        };
    });

    app.filter('split', function () {
        return function (value) {
            return value;
        };
    });

    app.filter('dict', function () {
        return function (value) {
            return value;
        };
    });

})();
