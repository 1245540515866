(function () {
    'use strict';

    angular.module('lucidity').controller('ActionCommentModalController', ActionCommentModalController);

    ActionCommentModalController.$inject = ['$scope', 'FormService', 'MessageService', 'ActionCommentService', 'ModalService', '$modalInstance', '$modalOptions', '_'];

    function ActionCommentModalController($scope, FormService, MessageService, ActionCommentService, ModalService, $modalInstance, $modalOptions, _) {
        var vm = this;

        ModalService.init($scope, $modalInstance, $modalOptions);
        $scope.form = {};
        $scope.comment = {};
        $scope.comments = {};
        $scope.show = false;
        vm.saveComment = saveComment;

        function saveComment() {

            $scope.bodyTemplate('/app/action/action-comment-modal.html');
            $scope.footerTemplate('/app/ui/modal/modal-footer-ok-cancel.html');
            $scope.comment = $modalOptions.comment || {};


            $scope.callback('ok', function ($modalScope) {
                $scope.startProcessing('ok');
                if (!$scope.form.addActionCommentForm.$valid ) {
                    $scope.show = true;
                    FormService.markAllDirty($scope.form.addActionCommentForm);
                    MessageService.error('Comment can\'t be empty error');
                    $scope.finishProcessing();
                    return;
                }

                if ($modalScope.comment.uuid) {
                    ActionCommentService.editComment($modalOptions.identifier, $modalScope.comment).then(function (comment) {
                        $scope.finishProcessing();
                        $modalInstance.close(comment);
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error saving comment. Please try again.');
                    });
                } else {
                    ActionCommentService.addComment($modalOptions.identifier, $modalScope.comment).then(function (comment) {
                        $scope.finishProcessing();
                        $modalInstance.close(comment);
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error saving comment. Please try again.');
                    });
                }
            });
        }

        saveComment();
    }
})();
