(function () {
    'use strict';

    angular.module('lucidity')
    .directive(
        "ngAnchor",
        ['$location', '$anchorScroll', function ($location, $anchorScroll) {
            return {
                restrict: "A",
                link: link
            };

            function link(scope, element, attrs) {
                scope.$on("$locationChangeSuccess", configureHref);

                element.on("click", function (event) {
                    $anchorScroll();
                });

                // Ensure the href is set on load so that every
                // fragment links can be be visited directly
                function configureHref() {

                    // Rewrite the path to root if it is empty to
                    // ensure the fragment is correctly appended
                    if ($location.path() === "") {
                        $location.path("/");
                        return;
                    }

                    var fragment = (attrs.ngAnchor || "");

                    if (fragment.charAt(0) === "#") {
                        fragment = fragment.slice(1);
                    }

                    var routeValue = ("#" + $location.url().split("#").shift());
                    var fragmentValue = ("#" + fragment);

                    attrs.$set("href", (routeValue + fragmentValue));
                }
            }
        }]
    );
})();
