(function () {
  'use strict';
  angular.module('lucidity').controller('ImpersonateController', [
    '$scope',
    '$window',
    'Restangular',
    'ModalService',
    'FormService',
    'MessageService',
    'applicationName',
    function ($scope, $window, Restangular, ModalService, FormService, MessageService, applicationName) {
      /**
       *  trigger point for opening a new modal
       * @param url
       * @param form_id
       * @param object_id
       * @param title
       * @param options
       */
      $scope.openModal = function () {

        var modalOptions = {
          backdrop: 'static',
        };

        var controllerOptions = {
          title: 'Impersonate',
          bodyUrl: '/app/impersonate/impersonate-modal.html',
          callbacks: {
            // save the modal and close
            ok: function ($modalScope, $modalInstance) {
              saveForm($modalScope, $modalInstance, function () {
                $modalInstance.dismiss($modalScope);
              });
            },
          },
        };

        ModalService.create(controllerOptions, modalOptions);
      };

      function saveForm($modalScope, $modalInstance, successPromise) {
        $modalScope.startProcessing('ok');
        var formElements = $modalScope.model;

        // check if the userID is not selected before OK button is pressed
        if (formElements==null || formElements.userId==null || formElements.userId.key==null) {
            $modalScope.errorMessage = "Please select a user first!";
            $modalScope.form.currentImpersonateForm.userId.$valid = false;
            $modalScope.form.currentImpersonateForm.userId.$pristine = false;
            FormService.markAllDirty($modalScope.form.currentImpersonateForm);
            $modalScope.finishProcessing();
            return;
        }

        submitForm(formElements).then(function (result) {
          successPromise();
          MessageService.success(result.data.meta.messages[0]);
          $modalScope.finishProcessing('ok');
          location.reload();
        }).catch(function (error) {
          $modalScope.errorMessage = error.data.result.messages[0];
          $modalScope.form.currentImpersonateForm.userId.$valid = false;
          $modalScope.form.currentImpersonateForm.userId.$pristine = false;
          FormService.markAllDirty($modalScope.form.currentImpersonateForm);
          $modalScope.finishProcessing();
        });
      }

      function submitForm(elements) {
        return Restangular.one('users', elements.userId.key).one('impersonate').get({application: (applicationName() || 'core')});
      }

      $scope.cancelImpersonation = function () {
        Restangular.all('users').one('cancelimpersonation').get()
          .then(function () {
            location.reload();
          });
      };
    },
  ]);
})();
