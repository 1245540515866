(function () {
  'use strict';
  angular.module('lucidity').directive('ngFlowUpload', [
    '_',
    'ValueParseService',
    function (_, ValueParseService) {
      var directive = {};
      directive.replace = false;
      directive.restrict = 'E';
      directive.templateUrl = '/app/element/flow-upload/uploadelement.html';

      directive.scope = {
        ngModel: '=',
        extension: '=ngFileExtension',
        filename: '=ngFileName',
        defaultValue: '=ngDefaultValue',
        disableSubmit: '=?ngDisableSubmit',
        persisted: '=?ngFilePersisted',
        flowAllowedExt: '=?ngFlowAllowedExt',
        maxFileSize: '@?ngFlowMaxFileSize'
      };

      directive.link = function (scope, element, attrs) {
        scope.isFileError = false;
        scope.errorMessages = [];
        scope.errorCode = null;
        scope.name = attrs.name;

        if (scope.defaultValue) {
            scope.ngModel = scope.defaultValue;
        }

        scope.toggleInfo = function (e) {
            $(e.target).closest('.element_flowupload_container').find('.allowed_extensions').toggle('500');
        }

        scope.startFlow = function (file, e) {
            if (!file || !e) {
                return false;
            }

            var fieldContainer = $(e.target).closest('.element_flowupload_container');
            if (!fieldContainer) {
                return false;
            }

            //#/ reset error panels
            fieldContainer.find('.panel-danger, .panel-info').hide();

            //#/ validate file extension
            var fileExtension = file.getExtension();
            var validExtensions = scope.flowAllowedExt ? scope.flowAllowedExt.replaceAll('.', '').split(',') : [];
            if (!fileExtension || !validExtensions || !validExtensions.length || validExtensions.indexOf(fileExtension.toLowerCase()) < 0) {
                fieldContainer.find('.file-type-error').show();
                return false;
            }

            //#/ validate full file name with extension
            var fullFileName = file.name;
            if (fullFileName.length <= 0 || fullFileName.length > 200 || !fullFileName.match(/^[a-zA-Z0-9._()\-\s]+$/)) {
                fieldContainer.find('.file-name-error').show();
                return false;
            }

            //#/ validate file size
            var fileSize = file.size;
            var maxFileSize = scope.maxFileSize ? (parseInt(scope.maxFileSize) * 1024 * 1024) : 0;
            if (!fileSize || !maxFileSize || fileSize > maxFileSize) {
                fieldContainer.find('.file-size-error').show();
                return false;
            }

            return true;
        };

        /**
         * Handle file upload completed
         * @param file
         * @param response
         */
        scope.fileAdded = function (file, response) {
          file.isUploading = false;
          file.isUploaded = true;
          var data = ValueParseService.jsonParse(response);
          scope.ngModel = data.data.filelink;
          scope.extension = data.data.extension;
          if (!scope.filename) {
            scope.filename = data.data.filename;
          }

          scope.disableSubmit = false;
        };

        /**
         * Handle file submitted for upload
         * @param flow
         * @param files
         */
        scope.fileSubmitted = function (flow, files) {
            scope.disableSubmit = true;
            scope.persisted = 0;
            scope.isFileError = false;
            flow.upload();
        };

        /**
         * Handle error when uploading
         * @param file
         * @param message
         * @param flow
         */
        scope.fileError = function (file, message, flow) {
            file.isError = true;
            file.isUploading = false;
            scope.isFileError = true;

            try {
                var response = JSON.parse(message);
                if (response.result && response.result.messages) {
                    scope.errorMessages = response.result.messages;
                    scope.errorCode = response.result.code;
                }
            } catch (e) {
                scope.errorMessages = ['An error occurred while uploading the file.'];
            }
        };

        scope.isInvalidExtension = function () {
            return (scope.errorCode === 4015 && scope.isFileError);
        };
      };

      return directive;
    },]);
})();
