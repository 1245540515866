(function () {
    'use strict';
    angular.module('lucidity').controller('UploadImageController', [
        '$scope',
        '$window',
        function ($scope, $window) {
            $scope.isFileError = false;
            $scope.errorMessages = [];
            $scope.errorCode = null;

            $scope.addFile = function (file, response) {
                file.isUploading = false;
                file.isUploaded = true;
                $scope.windowReloading = true;
                $window.location.reload();
            };

            $scope.startFlow = function (file, e) {
                $scope.windowReloading = false;

                if (!file || !e) {
                    return false;
                }

                var fieldContainer = $(e.target).closest('.photouploader');
                if (!fieldContainer) {
                    return false;
                }

                //#/ reset error panels
                fieldContainer.find('.panel-danger, .panel-info').hide();

                //#/ validate file extension
                var fileExtension = file.getExtension();
                var validExtensions = $.allowed_image_extensions ? $.allowed_image_extensions.replaceAll('.', '').split(',') : [];
                if (!fileExtension || !validExtensions || !validExtensions.length || validExtensions.indexOf(fileExtension.toLowerCase()) < 0) {
                    fieldContainer.find('.file-type-error').show();
                    return false;
                }

                //#/ validate full file name with extension
                var fullFileName = file.name;
                if (fullFileName.length <= 0 || fullFileName.length > 200 || !fullFileName.match(/^[a-zA-Z0-9._()\-\s]+$/)) {
                    fieldContainer.find('.file-name-error').show();
                    return false;
                }

                //#/ validate file size
                var fileSize = file.size;
                var maxFileSize = $.max_file_size ? (parseInt($.max_file_size) * 1024 * 1024) : 0;
                if (!fileSize || !maxFileSize || fileSize > maxFileSize) {
                    fieldContainer.find('.file-size-error').show();
                    return false;
                }

                return true;
            };

            $scope.submitFile = function (flow) {
                $scope.isFileError = false;
                flow.upload();
            };

            $scope.errorFile = function ($file, response, $flow) {
                $file.isUploading = false;
                $file.isUploaded = false;
                $file.error = true;
                $scope.isFileError = true;

                try {
                    response = JSON.parse(response);
                    if (response.result && response.result.messages) {
                        $scope.errorMessages = response.result.messages;
                        $scope.errorCode = response.result.code;
                    }
                } catch (e) {
                    $scope.errorMessages = ['An error occurred while uploading the file.'];
                }
            };

            $scope.isInvalidExtension = function () {
                return ($scope.errorCode === 4015 && $scope.isFileError);
            };

            $scope.$on('flow::fileSuccess', function (event, $flow, flowFile, response) {
                flowFile.cancel();
            });
        }
    ]);
}());
