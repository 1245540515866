(function() {
  'use strict';

  /**
   * 1. only used atm at intranet weather widget
   * 2. depends upon googleapis script at weather.twig
   */
  angular.module('lucidity').directive('ngAutocompleteGeolocation', [
    '_', '$window', '$interval',
    function(_, $window, $interval) {
      var directive = {};
      var addressComponents = {
        locality: 'locality',
        administrative_area_level_1: 'administrativearealevel1',
        country: 'country',
      };

      directive.restrict = 'A';
      directive.require = '?ngModel';
      directive.link = function(scope, element, attrs, ngModel) {
        scope.placeChanged = placeChanged;

        var options = {
          types: ['(cities)'],
          componentRestrictions: {'country': []},
        };

        var requiredAddressComponents = {};

        _.each(addressComponents, function(addressComponent, placeComponent) {
          if (attrs[addressComponent] !== undefined) {
            requiredAddressComponents[placeComponent] = attrs[addressComponent];
          }
        });

        checkGoogleMapLoaded();

        function checkGoogleMapLoaded() {
          var interval = $interval(waitForGoogleMap, 100);
          function waitForGoogleMap() {
            if($window.isGoogleMapLoaded === true){
              scope.gPlace = new google.maps.places.Autocomplete(element[0], options);
              google.maps.event.addListener(scope.gPlace, 'place_changed', scope.placeChanged);
              $interval.cancel(interval);
            }
          }
        }

        function placeChanged() {
          scope.$apply(function() {
            var currentPlace = scope.gPlace.getPlace();
            var currentAddressComponents = currentPlace['address_components'];

            if (ngModel) {
              ngModel.$setViewValue(currentPlace);
            }

            _.each(requiredAddressComponents, function(scopeVariable, addressComponent) {
              var requiredAddressComponent = null;
              _.each(currentAddressComponents, function(currentAddressComponent) {
                if (currentAddressComponent.types.indexOf(addressComponent) !== -1) {
                  requiredAddressComponent = currentAddressComponent.long_name;
                }

                _.setNested(scope, scopeVariable, requiredAddressComponent);
              });
            });
          });
        }
      };

      return directive;
    },
  ]);

})();
