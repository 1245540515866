(function () {
    'use strict';

    angular.module('lucidity').controller('ActionAttachmentController', ActionAttachmentController);

    ActionAttachmentController.$inject = ['ActionAttachmentService', 'ModalService', '_'];

    function ActionAttachmentController(ActionAttachmentService, ModalService, _) {
        var vm = this;

        vm.identifier = null; //Will be bound to Action's UUID by the view.
        vm.attachment = {
            name: '',
            description: '',
            linkUrl: '',
            deleted: false,
        };
        vm.attachments = [];
        vm.action_attachment_uuid = null;
        vm.loadActionAttachments = loadActionAttachments;
        vm.addAttachment = addAttachment;
        vm.editAttachment = editAttachment;
        vm.deleteAttachment = deleteAttachment;
        vm.processing = false;
        vm.flowAllowedExt = '';
        vm.maxFileSize = 0;

        function loadActionAttachments() {
            vm.attachments = [];
            var identifier = vm.identifier;

            if (window.location.pathname.indexOf("/intranet/actions/create") === -1 && window.location.pathname.indexOf("/intranet/myactions/create") === -1) {
                vm.processing = true;
                //Load attachments from storage for an action
                ActionAttachmentService.loadAttachments(identifier).then(function (attachments) {
                    vm.attachments = attachments.data.plain();
                    vm.processing = false;
                }).catch(function (data) {
                    vm.processing = false;
                });
            }
        }

        function addAttachment() {
            var addModalInstance = ModalService.create({
                title: 'Upload a Document',
                bodyUrl: '/app/action/action-attachment.html',
                identifier: vm.identifier,
                attachment: {},
                mode: 'add',
                flowAllowedExt: vm.flowAllowedExt,
                maxFileSize: vm.maxFileSize
            }, {
                controller: 'ActionAttachmentModalController',
            });

            addModalInstance.result.then(function (response) {
                vm.attachments.push(response.data.plain());
            });
        }

        function editAttachment(attachment) {
            var editModalInstance = ModalService.create({
                title: 'Update a Document',
                bodyUrl: '/app/action/action-attachment.html',
                identifier: vm.identifier,
                attachment: attachment,
                mode: 'edit',
            }, {
                controller: 'ActionAttachmentModalController',
            });

            editModalInstance.result.then(syncResponse);
        }

        function deleteAttachment(attachment) {
            ActionAttachmentService.deleteAttachment(vm.identifier, attachment).then(syncResponse);
        }

        function syncResponse(response) {
            vm.attachments = _.updateByKey(vm.attachments, 'uuidString', response.data.plain().uuidString, response.data.plain());
        }
    }
})();
