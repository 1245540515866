(function () {
    'use strict';
    angular.module('lucidity').controller('FancySelectController', [
        '$scope',
        'Restangular',
        '_',
        '$q',
        '$filter',
        '$interpolate',
        'EventService',
        function ($scope, Restangular, _, $q, $filter, $interpolate, EventService) {

            var valueInterpolator = null;
            var selectedOptionsKeys = {};
            var options = [];
            var data = {};
            var hasRefreshed = false;

            $scope.searchTerm = null;
            $scope.endpointParams = {};
            $scope.select = {
                model: null,
                selectedOptions: [],
            };

            $scope.filteredOptions = function () {
                var prefiltered = options;
                if ($scope.multiple) {
                    prefiltered = _.filter(options, function (option) {
                        return !existsAsValue(option.value);
                    });
                }

                prefiltered = _.filter(prefiltered, function (option) {
                    return $scope.filterCallback({option: option, extendedOption: findData(option.key)});
                });

                return $filter('filter')(prefiltered, $scope.searchTerm);
            };

            $scope.setMultiOptions = function (multiOptions) {
                if (multiOptions !== options) {
                    setOptions(multiOptions);
                }
            };

            $scope.setValue = function (value, initaliseValue) {
                var eventName = initaliseValue === true ? 'element.initialise' : 'element.update';
                value = _.isArray(value) ? value : [value];

                _.each(value, function (value) {
                    var option = findOption(value);
                    broadcastEvent(eventName, setValue(option));
                });
            };

            $scope.selectValue = function (item, model) {
                broadcastEvent('element.update', setValue(item));
                $scope.searchTerm = '';
            };

            $scope.removeValue = function (item, model) {
                $scope.searchTerm = '';
                $scope.select.selectedOptions = _.filter($scope.select.selectedOptions, function (selectedOption) {
                    if (selectedOption.key !== item.key) {
                        return true;
                    } else {
                        delete selectedOptionsKeys[selectedOption.key];
                        return false;
                    }
                });
            };

            $scope.refresh = _.debounce(refresh, 300);

            function refresh(searchTerm) {
                $scope.searchTerm = searchTerm;
                return getOptions(searchTerm).then(
                    function (response) {
                        $scope.setMultiOptions(response.data);
                        if (canAddOption()) {
                            addPrompt();
                        } else if (canAddTemporaryOptions() && ('' + searchTerm).length > 0) {
                            addTemporaryOption(searchTerm);
                        } else {
                            removePrompt();
                        }
                        hasRefreshed = true;
                    }
                );
            }

            $scope.setValueTemplate = function (valueTemplate) {
                $scope.valueTemplate = valueTemplate.replace(/\{+/g, '{{').replace(/}+/g, '}}');
                valueInterpolator = $interpolate($scope.valueTemplate);
                setOptions(data);
            };

            $scope.setMultiple = function (multiple) {
                $scope.multiple = multiple;
                if (multiple) {
                    $scope.select.model = [];
                } else {
                    $scope.select.model = {};
                }
            };

            function setValue(item) { //NOSONAR [INTEGRALCS-8795] Cognitive complexity is high
                if (item !== undefined) {
                    var extendedValue;
                    var key = item.key || item[dataKey()] || null;
                    if (key !== null && key !== undefined && item.key === undefined && item.value === undefined) { //NOSONAR
                        item.key = key;
                        item.value = valueInterpolator(item);
                    }

                    if (item.key === null && !existsAsValue($scope.searchTerm) && !existsAsOption($scope.searchTerm)) {
                        item.value = $scope.searchTerm;
                        postOption($scope.searchTerm).then(
                            function (response) {
                                var responseData = response.data.plain();
                                var key = responseData.key || responseData[dataKey()] || null;
                                if (key !== null) {
                                    item.key = key;
                                    addOption(key, item.value);
                                    if (!$scope.multiple) {
                                        $scope.select.selectedOptions = [];
                                        selectedOptionsKeys = {};
                                    }
                                    $scope.select.selectedOptions.push(item);
                                    if ($scope.multiple) {
                                        $scope.select.model = $scope.select.selectedOptions;
                                        extendedValue = _.map($scope.select.model, function (selected) {
                                            return findData(selected.key);
                                        });
                                    } else {
                                        extendedValue = findData(item.key);
                                    }
                                    selectedOptionsKeys[item.key] = item.key;
                                }
                                removePrompt();
                                return {item: item, extendedValue: extendedValue};
                            }
                        );
                    } else {
                        if (!$scope.multiple) {
                            $scope.select.selectedOptions = [];
                            selectedOptionsKeys = {};
                        }
                        $scope.select.selectedOptions.push(item);
                        if ($scope.multiple) {
                            $scope.select.model = $scope.select.selectedOptions;
                            extendedValue = _.map($scope.select.model, function (selected) {
                                return findData(selected.key);
                            });
                        } else {
                            extendedValue = findData(item.key);
                        }
                        selectedOptionsKeys[item.key] = item.key;
                        return {item: item, extendedValue: extendedValue};
                    }
                }
                return null;
            }

            function broadcastEvent(eventName, value) {
                if (value === null) {
                    return;
                }

                var event = {
                    scope: $scope,
                    value: value.item,
                    selected: $scope.select.model,
                    extendedValue: value.extendedValue,
                };

                if (eventName === 'element.update' && $scope.changeCallback !== undefined && _.isFunction($scope.changeCallback)) {
                    $scope.changeCallback(value.item, value.extendedValue);
                }
                EventService.broadcast(eventName, $scope.id, event);
            }

            function isValid(key, value, data) {
                if ($scope.validationCallback !== undefined && _.isFunction($scope.validationCallback)) {
                    return $scope.validationCallback(key, value, data);
                }
                return true;
            }

            function findOption(key) {
                if (_.isObject(key)) {
                    return key;
                }
                return _.find(options, function (option) {
                    return option.key === key || String(option.key) === String(key);
                });
            }

            function canAddOption() {
                return $scope.allowNewOptions && $scope.postEndpoint() !== undefined &&
                    $scope.postEndpoint() !== null && !existsAsValue($scope.searchTerm) &&
                    !existsAsOption($scope.searchTerm) && !existAsKey($scope.searchTerm);
            }

            function existsAsValue(value) {
                value = (value + '').toLowerCase();
                return _.find($scope.select.selectedOptions, function (option) {
                    return (option.value + '').toLowerCase() === value;
                });
            }

            function existsAsOption(value) {
                value = (value + '').toLowerCase();
                return _.find(options, function (option) {
                    return (option.value + '').toLowerCase() === value;
                });
            }

            function existAsKey(key) {
                return _.find(options, function (option) {
                    return (option.key !== undefined) && (option.key + '').toLowerCase() === key;
                });
            }

            function canAddTemporaryOptions() {
                return $scope.allowNewOptions && $scope.allowTemporaryOptions && $scope.filteredOptions().length === 0;
            }

            function addOption(key, value, temporaryOption) {
                var valueString = _.isString(value) ? value : valueInterpolator(value);
                if (isValid(key, valueString, value) && findData(key) === undefined) {
                    if (typeof value.archived != 'undefined' && value.archived) {
                        valueString += '[Archived]';
                    }
                    options.push({
                        key: key,
                        value: valueString,
                        temporaryOption: temporaryOption || false,
                    });
                    setData(key, value);
                }
            }

            function removeOption(key) {
                var optionToRemove = findOption(key);
                if (optionToRemove) {
                    if (optionToRemove.key !== undefined) {
                        deleteData(optionToRemove.key);
                    }
                    options = _.filter(options, function (option) {
                        return option !== optionToRemove;
                    });
                }
            }

            function keyToString(key) {
                if (_.isString(key) || _.isNumber(key)) {
                    return key;
                } else if (_.isArray(key) || _.isObject(key)) {
                    return _.keys(key).map(keyToString).join('');
                }
                return undefined;
            }

            function deleteData(key) {
                delete data[keyToString(key)];
            }

            function setData(key, value) {
                data[keyToString(key)] = value;
            }

            function findData(key) {
                return data[keyToString(key)] || undefined;
            }

            function addTemporaryOption(searchTerm) {
                _(options).filter(function (option) {
                    return selectedOptionsKeys[option.key] === undefined && (option.temporaryOption || false);
                }).map(removeOption).value();
                addOption(searchTerm, searchTerm, true);
            }

            function setOptions(multiOptions) {
                data = {};
                options = [];
                _.each(multiOptions, function (currentOption) {
                    if (_.isObject(currentOption)) {
                        addOption(currentOption[dataKey()], currentOption);
                    } else {
                        addOption(currentOption, currentOption);
                    }
                });
            }

            function dataKey() {
                return $scope.key || 'id';
            }

            function getOptions(searchTerm) {
                if (searchTerm !== '' && $scope.getEndpoint() !== undefined && $scope.getEndpoint() !== null && $scope.getEndpoint() !== '' && hasRefreshed === true) {
                    return Restangular.all($scope.getEndpoint()).getList({
                        params: $scope.endpointParams,
                        searchTerm: searchTerm
                    });
                } else {
                    var deferred = $q.defer();
                    deferred.resolve({data: options});
                    return deferred.promise;
                }
            }

            function postOption(option) {
                var postData = {};
                postData[$scope.postProperty] = option;
                if ($scope.postEndpoint() !== undefined && $scope.postEndpoint() !== null && $scope.postEndpoint() !== '') {
                    return Restangular.all($scope.postEndpoint()).post(postData);
                } else {
                    var deferred = $q.defer();
                    deferred.resolve({
                        data: {
                            plain: function () {
                                return {
                                    key: option,
                                    value: option,
                                    temporaryOption: false,
                                };
                            }
                        }
                    });
                    return deferred.promise;
                }
            }

            function addPrompt() {
                removeOption(null);
                deleteData(null);

                if ($scope.searchTerm !== '' && isValid($scope.searchTerm, $scope.searchTerm) && !existsAsValue($scope.searchTerm)) {
                    var value = 'Unable to find ' + $scope.searchTerm + '. Add?';
                    options.push({
                        key: null,
                        value: value,
                        temporaryOption: false,
                    });
                    setData(null, value);
                }
            }

            function removePrompt() {
                removeOption(null);
            }
        },
    ]);
})();
