(function () {
    'use strict';
    angular.module('lucidity').controller('FilingCabinetFileController', [
        '$scope',
        'Restangular',
        'TreeFactory',
        '_',
        function ($scope, Restangular, TreeFactory, _) {

            $scope.methods = $scope.methods || {};

            var template = '<p><a htmlprocessor filelink="%id%" source-title="%name%">%name%</a></p>';

            $scope.item = $scope.itemTree = TreeFactory.create(1);
            $scope.selectedFiles = {};
            $scope.selectedFilesCount = {};

            $scope.widget = {
                name: 'filingcabinet-file',
            };

            $scope.$on('ckeditor.dialog.onOk', function () {
                var templateString = _($scope.selectedFiles).map('data').sortBy('name').reduce(function (templateString, file) {
                    return templateString + template.replace(/%id%/, file.id).replace(/%name%/, file.name).replace(/%name%/, file.name);
                }, '');

                //Keep 'space' character so that multiple files won't be combined in a single <p> tag
                $scope.methods.insertRaw($scope, templateString);
            });

            $scope.clickItem = function (item) {
                if ($scope.isFolder(item)) {
                    $scope.clickFolder(item);
                } else {
                    $scope.clickFile(item);
                }
            };

            $scope.isFolder = function (item) {
                return item.data.isFolder;
            };

            $scope.clickFolder = function (item) {
                if (!$scope.isFolderOpen(item)) {
                    $scope.load(item.data.id);
                } else if ($scope.isFolderOpen(item)) {
                    item.data.expanded = false;
                }
            };

            $scope.isFolderOpen = function (item) {
                return item.data.expanded;
            };

            $scope.areChildrenLoaded = function (item) {
                return item.hasState(item.STATE_LOADED);
            };

            $scope.clickFile = function (item) {
                if ($scope.hasFile(item)) {
                    $scope.removeFile(item);
                } else {
                    $scope.addFile(item);
                }

                $scope.selectedFilesCount = _.keys($scope.selectedFiles).length;
            };

            $scope.hasFile = function (item) {
                return $scope.selectedFiles[item.data.id] !== undefined;
            };

            $scope.loadedWithNoChildren = function (item) {
                return item.data.isFolder && item.hasState(item.STATE_LOADED) && item.children.length === 0;
            };

            $scope.addFile = function (item) {
                item.data.selected = true;
                $scope.selectedFiles[item.data.id] = item;
            };

            $scope.removeFile = function (item) {
                item.data.selected = false;
                delete $scope.selectedFiles[item.data.id];
            };

            $scope.load = function (id) {
                var itemNode = $scope.itemTree.find(id);
                itemNode.state(itemNode.STATE_LOADING);
                Restangular.all('filingcabinet').one('folders', id).get().then(function (item) {
                        $scope.loading = false;
                        var children = item.data.children.data;
                        itemNode.state(itemNode.STATE_LOADED);
                        _.each(children, function (child) {
                            if (!$scope.itemTree.find(child.id)) {
                                child = _.merge(child, {
                                    selected: false,
                                    expanded: false,
                                });
                                itemNode.appendChild(child, child.id);
                            }
                        });
                    }
                );
            };

            $scope.load(1);

        },
    ]);
})();
