(function () {
  'use strict';
  angular.module('lucidity').factory('ModalService', [
    '$modal',
    '$sce',
    '_',
    '$interpolate',
    '$templateCache',

    function ($modal, $sce, _, $interpolate, $templateCache) {

      var service = {};
      service.form = {};
      service.processing = { bodyLoading: false, cancel: false, ok: false, active: false };

      service.callbacks = {
        ok: function ($scope, $modalInstance) {
          return $modalInstance.close($scope);
        },

        okDisabled: function ($scope, $modalInstance) {
          //@TODO move form related check out of generic service (currently used in Filing Cabinet)
          return $scope.disabled.ok || ($scope.form.currentForm && $scope.form.currentForm.$invalid) || $scope.processing.active;
        },

        cancelDisabled: function ($scope, $modalInstance) {
          return $scope.disabled.cancel || $scope.processing.active;
        },

        cancel: function ($scope, $modalInstance) {
          return $modalInstance.dismiss('cancel');
        },

        next: function ($scope, $modalInstance) {
        },

        load: function ($scope, $modalInstance) {
        },

        bodyTemplateLoaded: function ($scope, $modalInstance) {
        },

        headerTemplateLoaded: function ($scope, $modalInstance) {
        },

        footerTemplateLoaded: function ($scope, $modalInstance) {
        },

        bodyContentsLoaded: function ($scope, $modalInstance) {
          $scope.finishProcessing();
          $scope.load($scope, $modalInstance);
        },
      };

      service.disabled = {
        ok: false,
        cancel: false,
      };

      service.placeholders = {
        bodyUrl: '',
        body: undefined,
      };

      service.create = function (controllerOptions, modalOptions) {
        controllerOptions = _.merge({
          modalHeaderTemplate: '/app/ui/modal/modal-header.html',
          modalBodyTemplate: '/app/ui/modal/modal-body.html',
          modalFooterTemplate: '/app/ui/modal/modal-footer-ok-cancel.html',
        }, controllerOptions);

        modalOptions = _.merge(modalOptions, {
            backdrop: 'static',
        });

        var modalDefinition = {
          templateUrl: '/app/ui/modal/modal.html',
          controller: 'ModalController',
          resolve: {
            $modalOptions: function () {
              return controllerOptions;
            },
          },
        };

        modalDefinition = _.merge(modalDefinition, modalOptions || {});
        return $modal.open(modalDefinition);

      };

      service.title = function (title) {
        this.placeholders.title = title;
      };

      service.headerTemplate = function (headerTemplate) {
        this.placeholders.modalHeaderTemplate = headerTemplate;
      };

      service.bodyTemplate = function (bodyTemplate) {
        this.placeholders.modalBodyTemplate = bodyTemplate;
      };

      service.footerTemplate = function (footerTemplate) {
        this.placeholders.modalFooterTemplate = footerTemplate;
      };

      service.callback = function (callback, fn) {
        if (this.callbacks[callback] !== undefined) {
          var _this = this;
          _this[callback] = function () {
            return fn.call(_this, _this, _this.$modalInstance);
          };
        }
      };

      service.startProcessing = function (item) {
        this.processing.active = true;
        this.processing[item] = true;
      };

      service.finishProcessing = function (item) {
        var _this = this;
        if (item === undefined) {
          _.each(this.processing, function (processing, item) {
            _this.processing[item] = false;
          });
        } else {
          this.processing[item] = false;

        }

        var isActive = this.activeProcesses().length;
        if (isActive === 0) {
          this.processing.active = false;
        }

      };

      service.activeProcesses = function () {
        return _.filter(this.processing, function (isProcessing, key) {
          if (key !== 'active' && isProcessing) {
            return isProcessing;
          }

          return false;
        });
      };

      service.bodyUrl = function () {
        return this.placeholders.bodyUrl;
      };

      service.loadBody = function (templates) {

        this.startProcessing('bodyLoading');
        templates = templates || {};

        if (templates.headerTemplate !== undefined) {
          this.headerTemplate(templates.headerTemplate);
        }

        if (templates.bodyTemplate !== undefined) {
          this.bodyTemplate(templates.bodyTemplate);
        }

        if (templates.footerTemplate !== undefined) {
          this.footerTemplate(templates.footerTemplate);
        }

        if (this.$modalOptions.dynamicTemplateUrl !== undefined) {
          $templateCache.remove(this.$modalOptions.dynamicTemplateUrl);
          this.placeholders.bodyUrl = this.$modalOptions.dynamicTemplateUrl;
        }

        if (this.placeholders.body !== undefined) {
          this.bodyContentsLoaded(this, this.$modalInstance);
        }
      };

      service.init = function ($scope, $modalInstance, $modalOptions) {
        $scope = _.merge($scope, this);
        $scope.$modalOptions = $modalOptions || $scope.$modalOptions || {};
        $scope.$modalInstance = $modalInstance || $scope.$modalInstance || {};
        $scope.context = $scope.$modalOptions.context || {};
        $scope = _.merge($scope, $scope.context);
        $scope.model = $scope.$modalOptions.model || {};

        _.each(_.merge({}, this.callbacks, $scope.$modalOptions.callbacks || {}), function (fn, callback) {
          $scope.callback(callback, fn);
        });

        //Interpolate templates
        _.each($scope.$modalOptions, function (template, key) {
          if (_.isString(template)) {
            $scope.placeholders[key] = $interpolate(template)($scope);
          }
        });

        var methods = $scope.$modalOptions.methods || {};

        //Attach modal methods
        _.each(methods, function (method, key) {
          if (_.isFunction(method)) {
            $scope[key] = _.bind(method, this, $scope);
          }
        });

        return $scope;
      };

      return service;
    },
  ]);
})();
