(function () {
    'use strict';

    angular.module('lucidity').controller('ActionAttachmentModalController', ActionAttachmentModalController);

    ActionAttachmentModalController.$inject = ['$scope', 'FormService', 'MessageService', 'ActionAttachmentService', 'ModalService', '$modalInstance', '$modalOptions', '_'];

    function ActionAttachmentModalController($scope, FormService, MessageService, ActionAttachmentService, ModalService, $modalInstance, $modalOptions, _) {
        var vm = this;
        var editTemplate = '/app/action/action-attachment-modal-edit.html';
        var addTemplate = '/app/action/action-attachment-modal-add.html';
        var footerTemplate = '/app/ui/modal/modal-footer-ok-cancel.html';

        ModalService.init($scope, $modalInstance, $modalOptions);
        $scope.form = {};
        $scope.attachment = {};
        $scope.isFileError = false;
        $scope.errorMessages = [];
        $scope.errorCode = null;
        $scope.flowAllowedExt = $modalOptions.flowAllowedExt;
        $scope.maxFileSize = $modalOptions.maxFileSize;

        vm.saveAttachment = saveAttachment;

        function saveAttachment() {

            $scope.attachment = $modalOptions.attachment || {};

            if ($modalOptions.mode === 'edit'){
                $scope.bodyTemplate(editTemplate);
            } else {
                $scope.bodyTemplate(addTemplate);
            }
            $scope.footerTemplate(footerTemplate);

            $scope.callback('ok', function ($modalScope, $modalInstance) {
                $scope.startProcessing('ok');

                if ($modalOptions.mode === 'edit'){
                    if (!$scope.form.editActionAttachmentForm.$valid) {
                        FormService.markAllDirty($scope.form.editActionAttachmentForm);
                        MessageService.error('Validation error');
                        $scope.finishProcessing();
                        return;
                    }
                } else if (!$scope.form.addActionAttachmentForm.$valid) {
                    FormService.markAllDirty($scope.form.addActionAttachmentForm);
                    MessageService.error('Validation error');
                    $scope.finishProcessing();
                    return;
                }

                if ($modalScope.attachment.uuid) {
                    ActionAttachmentService.editAttachment($modalOptions.identifier, $modalScope.attachment).then(function (attachment) {
                        $scope.finishProcessing();
                        $modalInstance.close(attachment);
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error saving attachment. Please try again.');
                    });
                } else {
                    ActionAttachmentService.addAttachment($modalOptions.identifier, $modalScope.attachment).then(function (attachment) {
                        $scope.finishProcessing();
                        $modalInstance.close(attachment);
                    }, function () {
                        $scope.finishProcessing();
                        MessageService.error('Error saving attachment. Please try again.');
                    });
                }
            });
        }

        $scope.toggleInfo = function (e) {
            $(e.target).closest('.action-attachment-container').find('.allowed_extensions').toggle('500');
        }

        $scope.startFlow = function (file, e) {
            if (!file || !e) {
                return false;
            }

            var fieldContainer = $(e.target).closest('.action-attachment-container');
            if (!fieldContainer) {
                return false;
            }

            //#/ reset error panels
            fieldContainer.find('.panel-danger, .panel-info').hide();

            //#/ validate file extension
            var fileExtension = file.getExtension();
            var validExtensions = $scope.flowAllowedExt ? $scope.flowAllowedExt.replaceAll('.', '').split(',') : [];
            if (!fileExtension || !validExtensions || !validExtensions.length || validExtensions.indexOf(fileExtension.toLowerCase()) < 0) {
                fieldContainer.find('.file-type-error').show();
                return false;
            }

            //#/ validate file name
            var fullFileName = file.name;
            if (fullFileName.length <= 0 || fullFileName.length > 200 || !fullFileName.match(/^[a-zA-Z0-9._()\-\s]+$/)) {
                fieldContainer.find('.file-name-error').show();
                return false;
            }

            //#/ validate file size
            var fileSize = file.size;
            var maxFileSize = $scope.maxFileSize ? (parseInt($scope.maxFileSize) * 1024 * 1024) : 0;
            if (!fileSize || !maxFileSize || fileSize > maxFileSize) {
                fieldContainer.find('.file-size-error').show();
                return false;
            }

            // if attachment name is not provided, use the file name
            if (!$scope.attachment.name) {
                var fileNameWithoutExtension = fullFileName ? fullFileName.substring(0, fullFileName.lastIndexOf('.')) : '';
                $scope.attachment.name = fileNameWithoutExtension;
            }

            return true;
        };

        $scope.isInvalidExtension = function() {
            return ($scope.errorCode === 4015 && $scope.isFileError);
        };

        $scope.errorFile = function($file, response, $flow) {
            $file.isUploading = false;
            $file.isUploaded = false;
            $scope.disabled.ok = true;
            $file.error = true;
            $scope.isFileError = true;
            $scope.hasErrorMessage = true;

            try {
                response = JSON.parse(response);
                if (response.result && response.result.messages) {
                    $scope.errorMessages = response.result.messages;
                    $scope.errorCode = response.result.code;
                }
            } catch (e) {
                $scope.errorMessages = ['An error occurred while uploading the file.'];
            }
        };

        $scope.addFile = function($file, response, $flow) {
            if (response !== '' && $file.error !== true) {
                var tempFile = JSON.parse(response);
                $file.isUploading = false;
                $file.isUploaded = true;
                $scope.disabled.ok = false;
                $scope.attachment.tempFile = tempFile.data;
                $scope.isFileError = false;
                $scope.errorMessages = [];
            }
        };

        $scope.submitFile = function($flow, $files) {
            $flow.upload();
            $scope.disabled.ok = true;
            $scope.isFileError = false;
            $scope.errorMessages = [];
        };

        saveAttachment();
    }
})();
