(function () {
  'use strict';
  angular.module('lucidity').controller('DatepickerRepeatController', [
    '$scope',
    '_',
    '$window',
    '$document',
    '$rootScope',
    '$compile',
    '$interpolate',
    'moment',
    '$timeout',
    '$element',
    function ($scope, _, $window, $document, $rootScope, $compile, $interpolate, moment, $timeout, $element) {

      var backdropDomEl;
      var backdropScope;
      var dateInput;
      $scope.model = {};
      $scope.repeatDate = {
        date: null,
        repeat: {},
        interval: null,
        on: null,
        repeatDisplay: '',
      };

      $scope.isRepeating = false;
      $scope.isDateInputFocussed = false;
      $scope.manualDate = '';
      $scope.isOpen = false;
      $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1,
      };

      var defaultTemplate = $interpolate('Repeats {{ repeat.value }}');
      var weeklyOn = [
        { label: 'Mon', value: 1 },
        { label: 'Tue', value: 2 },
        { label: 'Wed', value: 3 },
        { label: 'Thu', value: 4 },
        { label: 'Fri', value: 5 },
        { label: 'Sat', value: 6 },
        { label: 'Sun', value: 7 },
      ];

      var weeklyLabel = $interpolate('{{ value > 1 ? value + \' weeks\' : \'every week\' }}');
      var monthlyLabel = $interpolate('{{ value > 1 ? value + \' months\' : \'every month\' }}');
      var monthlyOnLabel = $interpolate('{{ value|ordinal }} day');
      var monthlyOn = _(_.range(1, 29)).map(createOption).map(function (option) {
        option.label = monthlyOnLabel(option);
        return option;
      }).value();
      monthlyOn.push({ label: 'Last day', value: 'last' });

      $scope.$watch(
        function (scope) {
          var repeat = _.nested(scope, 'repeatDate.repeat.value', null);
          var interval = _.nested(scope, 'repeatDate.interval.value', null);
          var on = _.nested(scope, 'repeatDate.on', null);

          if (on === null || _.isEmpty(on)) {
            on = null;
          } else {
            on = !_.isArray(on) ? [on] : on;
            on = _(on).map(function (onOption) {
              return onOption.value;
            }).sortBy().value();
          }

          return {
            date: scope.repeatDate.date,
            repeat: repeat,
            interval: interval,
            on: on,
          };
        },

        function (newValue, oldValue, scope) {

          if (newValue.repeat !== oldValue.repeat) {
            newValue.interval = null;
            newValue.on = null;
            scope.repeatDate.interval = null;
            scope.repeatDate.on = null;
          }

          if (newValue.date !== null) {
            scope.manualDate = moment(newValue.date).format('DD/MM/YYYY');
          } else {
            scope.manualDate = '';
          }

          scope.model = {
            date: (newValue.date !== null) ? moment(newValue.date).format('YYYY-MM-DD') : null,
            repeat: newValue.repeat,
            interval: newValue.interval,
            on: newValue.on,
          };
          scope.repeatDate.repeatDisplay = $scope.getRepeatDisplay();
        },

        true
      );

      $scope.repeatOptions = [
        {
          label: 'Daily',
          value: 'daily',
          multipleOn: false,
          intervalOptions: [],
          onOptions: [],
        },
        {
          label: 'Weekly',
          value: 'weekly',
          repeatMultipleIntervalTemplate: $interpolate('Repeats every {{ interval.value }} weeks'),
          intervalMultipleOnTemplate: $interpolate('on {{ on|nested:\'label\'|join:\', \':\' and \' }}'),
          multipleOn: true,
          intervalOptions: _(_.range(1, 53)).map(createOption).map(function (option) {
            option.label = weeklyLabel(option);
            return option;
          }).value(),
          onOptions: weeklyOn,
        },
        {
          label: 'Monthly',
          value: 'monthly',
          repeatMultipleIntervalTemplate: $interpolate('Repeats every {{ interval.value }} months'),
          intervalMultipleOnTemplate: $interpolate('on the {{ on.value|ordinal }} day of the month'),
          multipleOn: false,
          intervalOptions: _([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 36, 48, 60]).map(createOption).map(function (option) {
            option.label = monthlyLabel(option);
            return option;
          }).value(),
          onOptions: monthlyOn,
        },
        {
          label: 'Yearly',
          value: 'yearly',
          multipleOn: false,
          intervalOptions: [],
          onOptions: [],
        },
      ];

      $scope.setValue = function (repeat, interval, on) {
        $scope.repeatDate.repeat = _.find($scope.repeatOptions, { value: repeat }) || {};
        $scope.repeatDate.interval = _.find($scope.repeatDate.repeat.intervalOptions, function (option) {
          return option.value === interval;
        });

        $scope.repeatDate.on = _.filter($scope.repeatDate.repeat.onOptions, function (option) {
          return on.indexOf(option.value) !== -1;
        });

        if (!$scope.repeatDate.repeat.multipleOn && _.isArray($scope.repeatDate.on)) {
          $scope.repeatDate.on = $scope.repeatDate.on.pop();
        }

        $scope.isRepeating = !_.isEmpty($scope.repeatDate.repeat);
      };

      $scope.updateAndClose = function () {
        $scope.updateDate();
        $scope.close();
      };

      $scope.updateDate = function () {
        $scope.isDateInputFocussed = false;
        if (_.isString($scope.manualDate)) {
          if ($scope.manualDate.replace(/\s+/g) === '') {
            $scope.repeatDate.date = null;
            return;
          }

          var parsedDate = moment($scope.manualDate, ['DD/MM/YYYY', 'D/M/YY', 'D/M/YYYY'], true);
          if (parsedDate.isValid()) {
            $scope.repeatDate.date = parsedDate.toDate();
          } else if ($scope.repeatDate.date) {
            $scope.manualDate = moment($scope.repeatDate.date).format('DD/MM/YYYY');
          } else {
            $scope.manualDate = '';
          }
        } else {
          $scope.manualDate = '';
        }
      };

      $scope.getRepeatDisplay = function () {
        var repeatDisplay = '';
        var date = _.nested($scope, 'repeatDate.date', null);
        var repeat = _.nested($scope, 'repeatDate.repeat.value', null);
        var interval = _.nested($scope, 'repeatDate.interval.value', null);
        var on = _.nested($scope, 'repeatDate.on', null);

        if (date !== null && repeat !== null) {
          var repeatTemplate = interval > 1 ? _.nested($scope, 'repeatDate.repeat.repeatMultipleIntervalTemplate', defaultTemplate) : defaultTemplate;
          repeat = repeatTemplate($scope.repeatDate);
          var intervalTemplate = _.nested($scope, 'repeatDate.repeat.intervalMultipleOnTemplate', function () {
            return '';
          });

          repeatDisplay = repeat + ' ' + intervalTemplate($scope.repeatDate);
        }

        return repeatDisplay;
      };

      $scope.toggleRepeatPanel = function () {
        $scope.isRepeating = !$scope.isRepeating;
        if (!$scope.isRepeating) {
          $scope.repeatDate.repeat = {};
          $scope.repeatDate.interval = null;
          $scope.repeatDate.on = null;
        }
      };

      $scope.open = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        open();
      };

      $scope.close = function () {
        $scope.isOpen = false;
        closeBackdrop();
      };

      $scope.getDayClass = function (date, mode) {
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }

        return '';
      };

      $scope.focusDateInput = function () {

        if (!dateInput) {
          dateInput = $element.querySelectorAll('.date-input');
          dateInput.on('click', function () {
            $scope.$apply(function () {
              open();
            });
          });
        }

        $scope.isDateInputFocussed = true;
        $timeout(function () {
          dateInput.focus();
        });

      };

      function open() {
        $scope.isOpen = true;
        createBackdrop();
        $scope.focusDateInput();
      }

      function createOption(option) {
        var value = option[0] || option;
        var label = option[1] || option;
        return { label: label, value: value };
      }

      function createBackdrop() {
        if (!backdropScope) {
          var body = $document.find('body').eq(0);
          backdropScope = $rootScope.$new(true);
          backdropScope.close = function () {
            if (backdropScope.isOpen) {
              backdropScope.isOpen = false;
              $scope.close();
            }

          };

          var angularBackgroundDomEl = angular.element('<div repeating-datepicker-backdrop></div>');
          backdropDomEl = $compile(angularBackgroundDomEl)(backdropScope);
          body.append(backdropDomEl);
        }

        backdropScope.isOpen = true;
      }

      function closeBackdrop() {
        if (backdropScope) {
          backdropScope.close();
        }
      }
    },
  ]);
})();
